<template>
  <div
    style=" position: fixed; top: 0; left: 0; min-width: 100%; min-height: 100%; background: url(.jpg), linear-gradient(180deg, #1A347F 0%, #0A2163 100%);"
    class="white--text text-center"
  >
    <p class="text-center mt-12 pt-4 fn20">
      ทดสอบภูมิแพ้ในแมว
    </p>
    <v-divider dark class="mb-8"></v-divider>
    <p class="fn20">คำถามข้อ 2 / {{ questions.length }}</p>
    <!-- คำถาม -->
    <div v-if="state == 'question'" class="mb-4">
      <div class="mb-8 white--text" style="font-weight: 700; font-size: 32px;">
        {{ question.question }}
      </div>
      <ul>
        <li
          v-for="answer in question.answers"
          :key="answer"
          class="ma-4 fn20"
          style="list-style-type: none; font-weight: 600;"
        >
          <v-btn
            class="mb-8 rounded-pill"
            style="background: linear-gradient(268.1deg, #D5E4FF 7.54%, #C1D1FF 100.72%);"
            block
            large
            @click="handleResponse(answer)"
            :data-id="answer.id"
          >
            {{ answer.answer }}
          </v-btn>
        </li>
        <li style="list-style-type: none;">
          <v-btn text color="white">ข้าม <a-icon type="right"/></v-btn>
        </li>
      </ul>
    </div>
    <!-- คำตอบ -->
    <div v-if="state == 'answer'" class="mb-4">
      <div class="mb-8 white--text" style="font-weight: 700; font-size: 32px;">
        {{ question.question }}
      </div>
      <ul>
        <li
          v-for="answer in question.answers"
          :key="answer"
          class="ma-4 fn20"
          style="list-style-type: none; font-weight: 600;"
        >
          <!-- style="background: linear-gradient(268.1deg, #819ECE 7.54%, #6477AC 100.72%);" -->
          <div
            class="mb-8 py-2 px-2 rounded-pill white--text"
            :class="{
              'green lighten-2': answer.correct,
              'red lighten-2':
                answer.id == selectedAnswer.id && !answer.correct,
            }"
          >
            {{ answer.answer }}
          </div>
        </li>
      </ul>
      <div class="text-center">
        <!-- <p v-if="selectedAnswer.correct" class="mt-8 text-lg">
          🎉 Correct, well done! 🎉
        </p>
        <p v-else class="mt-8 text-lg">Wrong answer, sorry</p> -->
        <button @click="handleNext">ไปข้อถัดไป <a-icon type="right" /></button>
      </div>
    </div>
    <div v-if="state == 'results'">
      <div class="mb-5 white--text" style="font-weight: 700; font-size: 32px;">
        คะแนนรวมทั้งหมด
      </div>
      <h3 class="my-5 py-3 white--text">
        {{ score }} / {{ questions.length }}
      </h3>
      <div class="text-center">
        <button v-on:click="handleRestart">
          เริ่มใหม่
        </button>
      </div>
    </div>
    <!-- </v-parallax> -->
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { Encode, Decode } from "@/services";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      image: require("@/assets/BG_Quiz.png"),
      imageCorrect: require("@/assets/BG_correct.png"),
      state: "question",
      currentQuestion: 0,
      selectedAnswer: 0,
      score: 0,
      questions: [
        {
          question: `FAS ย่อมาจากอะไร`,
          no: 1,
          answers: [
            {
              answer: "Feline Atopic Syndrome",
              correct: false,
            },
            {
              answer: "Feline Anti-allegic Syndrome",
              correct: true,
            },
            {
              answer: "Feline Animal Syndrome",
              correct: false,
            },
          ],
        },
        {
          question: `allergen avoidance คืออะไร`,
          no: 2,
          answers: [
            {
              answer: "การหลีกเลี่ยงสารก่อภูมิแพ้",
              correct: true,
            },
            {
              answer: "อาการที่แมวที่เป็นภูมิแพ้ควรหลีกเลี่ยง",
              correct: false,
            },
            {
              answer: "การเลี่ยงสัตว์ที่เป็นภูมิแพ้",
              correct: false,
            },
          ],
        },
      ],

      category: "",
      itemsCategory: [],
      type: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],
      items: [],
      search: "",
      count: 0,
    };
  },
  computed: {
    question() {
      return this.questions[this.currentQuestion];
    },
    scorePercentage() {
      return (this.score * 100) / this.questions.length;
    },
  },

  methods: {
    handleResponse(answer) {
      this.selectedAnswer = answer;
      if (answer.correct) {
        console.log(answer.correct, "selected.correct");
        this.score++;
      }
      this.state = "answer";
    },
    handleNext(event) {
      this.currentQuestion++;
      if (this.currentQuestion >= this.questions.length) {
        this.state = "results";
      } else {
        this.state = "question";
      }
    },
    // randomizeOrder() {
    //   this.questions.map((question) => {
    //     question.answers.sort((a, b) => Math.random() - 0.5);
    //     question.answers.map((answer, index) => (answer.id = index));
    //   });
    //   this.questions.sort((a, b) => Math.random() - 0.5);
    // },
    handleRestart(event) {
      this.currentQuestion = 0;
      this.score = 0;
      this.state = "question";
    },

    async dropdown() {
      const cateDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/categories`
      );
      // const typeDropdown = await this.axios.get(
      //   `${process.env.VUE_APP_API}/types`
      // );
      const brandDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/brands`
      );
      const companyDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/companies`
      );
      this.itemsCategory = cateDropdown.data.data;
      // this.itemsType = typeDropdown.data.data;
      this.itemsBrand = brandDropdown.data.data;
      this.itemsCompany = companyDropdown.data.data;
      console.log("companyDropdown", this.itemsCompany);
    },
    async getAllProduct() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/findAllProductGroup?brandId=` +
          this.brand +
          "&typeId=" +
          this.type +
          "&categoryId=" +
          this.category
        // 'http://localhost:8081/types/'
      );
      console.log("getAllproducts", response.data.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },

    goToCreate() {
      this.$router.push("createproduct");
    },
    viewProduct(val) {
      localStorage.setItem("productdata", Encode.encode(val));
      this.$router.push("viewProduct");
    },
    UpdateProduct(val) {
      localStorage.setItem("productdata", Encode.encode(val));
      this.$router.push("EditProduct");
    },
    async DeleteProduct(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("userBiData"))
          );
          const auth = {
            headers: {
              Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
            },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/products/deleteProductGroup/` + val.id,
            // `${process.env.VUE_APP_API}/products/` + val.id,
            auth
          );
          console.log(response);
          if (response.data.response_status == "ERROR") {
            this.$swal.fire({
              icon: "error",
              text: response.data.message,
              showConfirmButton: false,
              timer: 2000,
            });
          } else {
            this.$swal.fire({
              icon: "success",
              text: "ลบข้อมูลสำเร็จ",
              showConfirmButton: false,
              timer: 1000,
            });
            // await location.reload();
            this.getAllProduct();
          }
        }
      });
    },
  },
};
</script>

<style scoped>
body {
  height: 100%;
  /* margin: 0; */
}
</style>
